import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Spinner } from "react-bootstrap";

const Pti = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  const category = "entertainment"; // Hardcoded category
  const keywords = " puspa 2,";
  // Helper function to get today's and yesterday's date in the required format
  const getDateRange = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 50);

    const formatDate = (date) => date.toISOString().split("T")[0]; // Format: YYYY-MM-DD

    return `${formatDate(yesterday)},${formatDate(today)}`;
  };

  const dateRange = getDateRange(); // Get today's and yesterday's date range

  // Function to fetch news for the "entertainment" category
  const fetchNews = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.mediastack.com/v1/news?access_key=9972afa0eb1d94f3077ab41d7f6b561e&languages=en&keywords=primeminister,president,trump,modi&date=${dateRange}&published_desc`
      );
      const data = await response.json();
      setArticles(data.data || []);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <Container>
      <h1 className="my-4">News</h1>
      <div className="mt-4">
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Loading...</p>
          </div>
        ) : (
          <Row>
            {articles.map((article, index) => (
              <Col key={index} md={12} className="mb-4">
                <Card>
                  <Row>
                    <Col md={4}>
                      <Card.Img
                        className="img-fluid"
                        variant="left"
                        src={article.image || "https://via.placeholder.com/150"}
                        alt={article.title}
                      />
                    </Col>
                    <Col md={8}>
                      <Card.Body>
                        <Card.Title>{article.title}</Card.Title>
                        <Card.Text>
                          {article.description || "No description available."}
                        </Card.Text>
                        <Card.Text>
                          {article.published_at ||
                            "No publication date available."}
                        </Card.Text>
                        <Card.Text>
                          {article.source || "No publication date available."}
                        </Card.Text>
                        <Card.Link href={article.url} target="_blank">
                          Read More
                        </Card.Link>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </Container>
  );
};

export default Pti;
