import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";

const HomeNewsDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let storedArticle = location.state?.article; // Retrieve article from state (passed via navigate)

    if (!storedArticle) {
      // Retrieve from sessionStorage if no article is in state
      storedArticle = JSON.parse(sessionStorage.getItem("article"));
    }

    if (storedArticle) {
      setArticle(storedArticle);
      setLoading(false); // No need for setTimeout, we can directly set loading state
    } else {
      setLoading(false); // Handle loading state if no article is found
    }
  }, [location.state?.article]);

  if (loading)
    return (
      <div className="text-center my-5">
        <Spinner animation="border" />
        <p>Loading...</p>
      </div>
    );

  if (!article)
    return (
      <div className="text-center text-danger">Error: Article not found.</div>
    );

  return (
    <>
      <NavBar />
      <Container className="mt-4">
        <Row>
          {/* Main News Content */}
          <Col lg={9}>
            <h2 className="fw-bold">{article.title}</h2>
            <small
              style={{ fontSize: "10px" }}
              className="text-uppercase text-primary fw-bold"
            >
              {article.source || "Unknown Source"}
            </small>
            <p>{new Date(article.published_at).toLocaleDateString()}</p>
            <hr />
            {article.image && article.image.includes(".mp4") ? (
              <video controls className="img-fluid">
                <source src={article.image} type="video/mp4" />
              </video>
            ) : (
              <img
                src={article.image}
                className="img-fluid"
                alt={article.title}
              />
            )}
            <hr />
            <div className="text-secondary-emphasis">
              <p>{article.description || "No description available."}</p>
            </div>
            <hr />
          </Col>

          {/* Advertisement Section */}
          <Col lg={3}>
            <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
            <img
              src="https://s0.2mdn.net/simgad/6463644956967963792"
              alt="Advertisement"
              height={"250px"}
              width={"300px"}
              className="mb-2"
            />
            <img
              src="https://s0.2mdn.net/simgad/6463644956967963792"
              alt="Advertisement"
              height={"250px"}
              width={"300px"}
            />
          </Col>
        </Row>

        {/* Back Button */}
        <Button
          onClick={() => {
            // Clear sessionStorage when leaving the page entirely (optional)
            // sessionStorage.removeItem("article");
            navigate(-2); // Go back to the previous page
          }}
          className="mt-3"
        >
          ⬅ Back
        </Button>
      </Container>
    </>
  );
};

export default HomeNewsDetails;
