//import logo from './logo.svg';
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./App.css";
import { GeoLocationProvider } from "./Components/GeoLocation";
import { FlagProvider } from "./Components/FlagContext";
import Home from "./Pages/Home/Home";
import Sports from "./Pages/Sports/Sports";
import Technology from "./Pages/Technology/Technology";
import Events from "./Pages/Eventss/Events";
import Health from "./Pages/Health/Health";
import Entertainment from "./Pages/Entertainment/Entertainment";
import Immigiration from "./Pages/Immigiration";
import SportsDetail from "./Components/SportsDetail";
import MovieDetails from "./Components/MovieDetails";
import MasterDetails from "./Components/MasterDetails";
import ImmigirationVisa from "./Pages/ImmigirationVisa";
import AustralianImmigrationGuidelines from "./Pages/immaus";
import UKVisaGuidelines from "./Pages/immuk";
import NewZealandImmigrationGuidelines from "./Pages/immnw";
import NetherlandsImmigrationGuidelines from "./Pages/immnl";
import CanadaImmigrationGuidelines from "./Pages/immca";
import JapanImmigrationGuidelines from "./Pages/immjp";
import SwitzerlandImmigrationGuidelines from "./Pages/immsl";
import GermanyImmigrationGuidelines from "./Pages/immgy";
import USImmigrationGuidelines from "./Pages/Immusa";
import ImmigirationDetails from "./Pages/ImmigirationDetails";
import EventDetail from "./Components/EventDetail";
import Feeder from "./Pages/Feeder/Feeder";
import Auto from "./Pages/Auto/Auto";
import Blogs from "./Pages/Blogs/Blogs";
import HealthDetails from "./Pages/Health/HealthDetails";
import NewsDisplay from "./Pages/NewsDisplay";
import Pti from "./Pages/pti";
import HomeNewsDetails from "./Components/HomePage/HomeNewsDetails";
import Footer from "./Components/Footer/Footer";
import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";

function App() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "https://ayg.s3.us-east-2.amazonaws.com/bharat/WhatsApp+Image+2024-08-21+at+10.57.37+PM.jpeg",
    "https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0060.jpg",
    "https://ayg.s3.us-east-2.amazonaws.com/bharat/WhatsApp+Image+2024-08-21+at+10.57.37+PM.jpeg",
    "https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0059.jpg",
    "https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0058.jpg",
  ];

  // useEffect hook to handle image rotation
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current image index, reset to 0 if it exceeds the number of images
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // 4 seconds interval
    return () => {
      // Clear the interval when the component unmounts
      clearInterval(interval);
    };
  });
  return (
    <div className="App">
      <GeoLocationProvider>
        <FlagProvider>
          <BrowserRouter>
            {/* ............Banner Advertisement...................... */}
            <section style={{ backgroundColor: "rgb(238, 238, 238" }}>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <Image
                  className="mx-auto d-block pt-4 pb-3"
                  src={images[currentImageIndex]}
                  fluid
                />
              </Link>
            </section>
            <Routes>
              <Route index element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/events" element={<Events />} />
              <Route path="/events/:id" element={<EventDetail />} />
              <Route path="home/events/:id" element={<EventDetail />} />
              <Route path="/health" element={<Health />} />
              <Route path="/health/:id" element={<HealthDetails />} />
              <Route path="/entertainment" element={<Entertainment />} />
              <Route path="/sports/*" element={<Sports />} />
              <Route path="/immigiration" element={<Immigiration />} />
              <Route
                path="/immigration-details/:id"
                element={<ImmigirationDetails />}
              />
              <Route
                path="/immigiration/india"
                element={<ImmigirationVisa />}
              />
              <Route
                path="/immigiration/usa"
                element={<USImmigrationGuidelines />}
              />
              <Route path="/immigiration/uk" element={<UKVisaGuidelines />} />
              <Route
                path="/immigiration/australia"
                element={<AustralianImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/NewZealand"
                element={<NewZealandImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/Netherlands"
                element={<NetherlandsImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/canada"
                element={<CanadaImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/japan"
                element={<JapanImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/Switzerland"
                element={<SwitzerlandImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/germany"
                element={<GermanyImmigrationGuidelines />}
              />
              <Route path="/technology/*" element={<Technology />} />
              <Route path="/news-detail" element={<HomeNewsDetails />} />
              <Route path="/news/:id" element={<MasterDetails />} />
              <Route path="home/sports/:id" element={<SportsDetail />} />

              <Route
                path="/entertainment/movies/:id"
                element={<MovieDetails />}
              />
              <Route path="/auto" element={<Auto />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/feeder/*" element={<Feeder />} />
              <Route path="/newsapi" element={<NewsDisplay />} />
              <Route path="/pti" element={<Pti />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </FlagProvider>
      </GeoLocationProvider>
    </div>
  );
}

export default App;
