import React from "react";
import PropTypes from "prop-types";
import styles from "./HomePage.module.css";
import HomeMaster from "../HomePage/HomeMaster";
import HomeMaster1 from "../HomePage/HomeMaster1";
import HomeMaster2 from "../HomePage/HomeMaster2";
import HomeEvents from "./HomeEvents";
import HomeSports from "./HomeSports";

const HomePage = () => (
  <div className={styles.HomePage}>
    {/* <HomeMaster /> */}
    <HomeMaster2 />
    <HomeMaster1 />
    <HomeEvents />
    <HomeSports />
  </div>
);

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
