import React, { useState, useEffect } from "react";
import { Nav, Container, Card, Row, Col, Spinner } from "react-bootstrap";

const NewsPage = () => {
  const [articles, setArticles] = useState([]);
  const [category, setCategory] = useState("business");
  const [loading, setLoading] = useState(false);
  const [categoryCounts, setCategoryCounts] = useState({});

  const categories = [
    "business",
    "sports",
    "technology",
    "entertainment",
    "health",
    "science",
  ];

  // Helper function to get today's and yesterday's date in the required format
  const getDateRange = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formatDate = (date) => date.toISOString().split("T")[0]; // Format: YYYY-MM-DD

    return `${formatDate(yesterday)},${formatDate(today)}`;
  };

  const dateRange = getDateRange(); // Get today's and yesterday's date range

  // Function to fetch news for a specific category
  const fetchNews = async (selectedCategory) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.mediastack.com/v1/news?access_key=9972afa0eb1d94f3077ab41d7f6b561e&languages=en&categories=${selectedCategory}&countries=us&date=${dateRange}`
      );
      const data = await response.json();
      setArticles(data.data || []);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
    setLoading(false);
  };

  // Function to fetch article counts for all categories
  const fetchCategoryCounts = async () => {
    const counts = {};
    for (const cat of categories) {
      try {
        const response = await fetch(
          `https://api.mediastack.com/v1/news?access_key=9972afa0eb1d94f3077ab41d7f6b561e&languages=en&categories=${cat},in&date=${dateRange}&sources=cnn`
        );
        const data = await response.json();
        counts[cat] = data.data ? data.data.length : 0;
      } catch (error) {
        console.error(`Error fetching count for ${cat}:`, error);
        counts[cat] = 0; // Default to 0 if there's an error
      }
    }
    setCategoryCounts(counts);
  };

  useEffect(() => {
    fetchNews(category);
  }, [category]);

  useEffect(() => {
    fetchCategoryCounts();
  }, []);

  return (
    <Container>
      <h1 className="my-4">News Page</h1>
      <Nav
        variant="tabs"
        activeKey={category}
        onSelect={(selectedKey) => setCategory(selectedKey)}
      >
        {categories.map((cat) => (
          <Nav.Item key={cat}>
            <Nav.Link eventKey={cat}>
              {cat.charAt(0).toUpperCase() + cat.slice(1)}{" "}
              {categoryCounts[cat] !== undefined
                ? `(${categoryCounts[cat]})`
                : "(0)"}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <div className="mt-4">
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Loading...</p>
          </div>
        ) : (
          <Row>
            {articles.map((article, index) => (
              <Col key={index} md={12} className="mb-4">
                <Card>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <Card.Img
                        className="img-fluid"
                        variant="left"
                        src={article.image || "https://via.placeholder.com/150"}
                        alt={article.title}
                      />
                    </Col>
                    <Col md={8}>
                      <Card.Body>
                        <Card.Title>{article.title}</Card.Title>
                        <Card.Text>
                          {article.description || "No description available."}
                        </Card.Text>
                        <Card.Text>
                          {article.published_at || "No published_at available."}
                        </Card.Text>
                        <Card.Link href={article.url} target="_blank">
                          Read More
                        </Card.Link>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </Container>
  );
};

export default NewsPage;
